<template>
  <div class="term--service-wrapper">
    <banner :banner-text="bannerText" />
    <div class="row">
      <div class="col-12 col-md-6 text-center d-flex justify-content-center main--image-wrapper">
        <img
          class="main--image"
          src="https://rentfix.s3-ap-southeast-1.amazonaws.com/static/img/static/photo-home-owner-auckland.jpg"
        />
      </div>
      <div v-html="$t('tos.opening')" class="col-12 col-md-6 opening"></div>
      <div class="col-12 intro--wrapper">
        <div class="introduction" v-html="$t('privacy.content_intro')"></div>
      </div>
      <div class="col-12 content--wrapper">
        <bookmark class="mb-4"></bookmark>
        <personal-use class="mb-4"></personal-use>
        <restrictions class="mb-4"></restrictions>
        <prop-cat class="mb-4"></prop-cat>
        <prop-list class="mb-4"></prop-list>
        <space-manager class="mb-4"></space-manager>
        <third-party class="mb-4"></third-party>
        <copyright class="mb-4"></copyright>
        <intel-prop class="mb-4"></intel-prop>
        <compensation class="mb-4"></compensation>
        <choice-of-law class="mb-4"></choice-of-law>
        <changes class="mb-4"></changes>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/utils/page-banner';
import Bookmark from '@/components/static-page/term-of-service/bookmark';
import PersonalUse from '@/components/static-page/term-of-service/personal-use';
import Restrictions from '@/components/static-page/term-of-service/restrictions';
import PropCat from '@/components/static-page/term-of-service/property-category';
import PropList from '@/components/static-page/term-of-service/property-listings';
import SpaceManager from '@/components/static-page/term-of-service/space-manager';
import ThirdParty from '@/components/static-page/term-of-service/third-party';
import Copyright from '@/components/static-page/term-of-service/copyright';
import IntelProp from '@/components/static-page/term-of-service/intellectual-property';
import Compensation from '@/components/static-page/term-of-service/compensation';
import ChoiceOfLaw from '@/components/static-page/term-of-service/choice-of-law';
import Changes from '@/components/static-page/term-of-service/changes';

export default {
  name: 'term-of-service',
  components: {
    Banner,
    Bookmark,
    PersonalUse,
    Restrictions,
    PropCat,
    PropList,
    SpaceManager,
    ThirdParty,
    Copyright,
    IntelProp,
    Compensation,
    ChoiceOfLaw,
    Changes,
  },
  computed: {
    bannerText() {
      return this.$t('tos.title');
    },
  },
};
</script>
