<template>
  <div class="container bg-white" id="propList">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('tos.propList.title') }}</h3>
        <div class="divider"></div>
        <div>{{ $t('tos.propList.line1') }}</div>
        <div>
          {{ $t('tos.propList.line2') }}
          <ul>
            <li>{{ $t('tos.propList.line3') }}</li>
            <li>{{ $t('tos.propList.line4') }}</li>
            <li>{{ $t('tos.propList.line5') }}</li>
            <li>{{ $t('tos.propList.line6') }}</li>
            <li>{{ $t('tos.propList.line7') }}</li>
            <li>{{ $t('tos.propList.line8') }}</li>
          </ul>
          <div>{{ $t('tos.propList.line9') }}</div>
          <div>{{ $t('tos.propList.line10') }}</div>
          <div>{{ $t('tos.propList.line11') }}</div>
          <div>{{ $t('tos.propList.line12') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'propList',
};
</script>
