<template>
  <div class="container bg-white" id="copyright">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('tos.copyright.title') }}</h3>
        <div class="divider"></div>
        <div>{{ $t('tos.copyright.line1') }}</div>
        <ol>
          <li>{{ $t('tos.copyright.line2') }}</li>
          <li>{{ $t('tos.copyright.line3') }}</li>
          <li>{{ $t('tos.copyright.line4') }}</li>
          <li>{{ $t('tos.copyright.line5') }}</li>
        </ol>
        <div>{{ $t('tos.copyright.line6') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'copyright',
};
</script>
