<template>
  <div class="container bg-white">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('tos.bookmark.title') }}</h3>
        <div class="divider"></div>
        <ol type="1">
          <li>
            <a href="#personalUse">{{ $t('tos.personalUse.title') }}</a>
          </li>
          <li>
            <a href="#restrictions">{{ $t('tos.restrictions.title') }}</a>
          </li>
          <li>
            <a href="#propCat">{{ $t('tos.propCat.title') }}</a>
          </li>
          <li>
            <a href="#propList">{{ $t('tos.propList.title') }}</a>
          </li>
          <li>
            <a href="#spaceManager">{{ $t('tos.spaceManager.title') }}</a>
          </li>
          <li>
            <a href="#thirdParty">{{ $t('tos.thirdParty.title') }}</a>
          </li>
          <li>
            <a href="#copyright">{{ $t('tos.copyright.title') }}</a>
          </li>
          <li>
            <a href="#intelProp">{{ $t('tos.intelProp.title') }}</a>
          </li>
          <li>
            <a href="#compensation">{{ $t('tos.compensation.title') }}</a>
          </li>
          <li>
            <a href="#choiceOfLaw">{{ $t('tos.choiceOfLaw.title') }}</a>
          </li>
          <li>
            <a href="#changes">{{ $t('tos.changes.title') }}</a>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bookmark',
};
</script>
