<template>
  <div class="container bg-white" id="spaceManager">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('tos.spaceManager.title') }}</h3>
        <div class="divider"></div>
        <div>{{ $t('tos.spaceManager.line1') }}</div>
        <div>
          {{ $t('tos.spaceManager.line2') }}
          <ol>
            <li>{{ $t('tos.spaceManager.line3') }}</li>
            <li>{{ $t('tos.spaceManager.line4') }}</li>
            <li>{{ $t('tos.spaceManager.line5') }}</li>
            <li>{{ $t('tos.spaceManager.line6') }}</li>
          </ol>
        </div>
        <div>{{ $t('tos.spaceManager.line7') }}</div>
        <div>{{ $t('tos.spaceManager.line8') }}</div>
        <div>{{ $t('tos.spaceManager.line9') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'spaceManager',
};
</script>
