<template>
  <div class="container bg-white" id="restrictions">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('tos.restrictions.title') }}</h3>
        <div class="divider"></div>
        <div>
          {{ $t('tos.restrictions.line1') }}
          <ol>
            <li>{{ $t('tos.restrictions.line2') }}</li>
            <li>{{ $t('tos.restrictions.line3') }}</li>
            <li>{{ $t('tos.restrictions.line4') }}</li>
            <li>{{ $t('tos.restrictions.line5') }}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'restrictions',
};
</script>
