<template>
  <div class="container bg-white" id="propCat">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('tos.propCat.title') }}</h3>
        <div class="divider"></div>
        <div>
          {{ $t('tos.propCat.line1') }}
          <ol>
            <li>{{ $t('tos.propCat.line2') }}</li>
            <li>{{ $t('tos.propCat.line3') }}</li>
            <li>{{ $t('tos.propCat.line4') }}</li>
          </ol>
          <div>{{ $t('tos.propCat.line5') }}</div>
          <div>{{ $t('tos.propCat.line6') }}</div>
          <div>{{ $t('tos.propCat.line7') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'propCat',
};
</script>
